export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.hook("vuetify:configuration", vuetify => {
    vuetify.vuetifyOptions = vuetify.vuetifyOptions || {};
    vuetify.moduleOptions = vuetify.moduleOptions || { styles: { configFile: "./styles/variables.scss" } };
    Object.assign(vuetify.vuetifyOptions, {
      theme: {
        defaultTheme: "light",
        themes: {
          light: {
            dark: false,
            colors: {
              rating: "#e8b440",
              button: "#f95a00",
              breadcrumbs: "#FFFFFF",
              cardTitle: "#1950bd",
              linkColor: "#0066ff",
              jackpot: "#1950bd",
              footerLink: "#969EA2",
              // buttonTextColor: "#ffffff",
              // buttonBackground: "#3a96aa",
              iconColor: "#0066ff",
              toTop: "#fcac18",
              footer: "#212529",
              blueButton: "#1950bd",
            },
          },
          dark: {},
        },
      },
    });
  });
});
